import { styled } from 'styled-components';

import { mq } from '@/shared/ui/styled';

export const FAQ_ITEM_OPENED_CLASS = 'opened';

export const Wrapper = styled.li`
    border-bottom: 1px solid ${({ theme }) => theme.color.neutral400};
    padding-bottom: 12px;
    margin-bottom: 16px;

    &:last-of-type {
        margin-bottom: 0;
        padding-bottom: 0;
        border-bottom: none;
    }
`;

export const ItemQuestion = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: ${({ theme }) => theme.fontSize.sm};
    font-weight: 500;
    cursor: pointer;
    -webkit-tap-highlight-color: rgb(0 0 0 / 0%);

    ${mq.gt.sm} {
        font-size: ${({ theme }) => theme.fontSize.md};
    }
`;

export const ItemToggleButton = styled.button`
    display: flex;
    flex: none;
    background-color: #eef1f4;
    border: none;
    border-radius: 50%;
    width: 24px;
    height: 24px;
    padding: 6px;
    position: relative;
    cursor: pointer;

    &::before,
    &::after {
        content: '';
        align-self: center;
        background-color: #000;
        display: block;
        width: 50%;
        height: 1px;
        position: absolute;
    }

    &::before {
        transform: rotate(90deg);
    }

    .${FAQ_ITEM_OPENED_CLASS} & {
        transform: rotate(45deg);
    }

    ${mq.gt.sm} {
        width: 36px;
        height: 36px;
        padding: 9px;
    }
`;

export const ItemAnswer = styled.div`
    height: 0;
    opacity: 0;
    font-size: ${({ theme }) => theme.fontSize.sm};
    line-height: 1.2;
    overflow: hidden;
    margin-right: 10%;
    transition: all 0.1s ease-out;

    .${FAQ_ITEM_OPENED_CLASS} > & {
        transition: all 0.3s ease;
        height: 100%;
        opacity: 1;
        padding-top: 12px;
    }
`;
