import cn from 'classnames';
import React, { FC, useState } from 'react';

import * as Styled from './styled';

export type TProps = {
    question: string;
    answer: string;
};

export const QuestionItem: FC<TProps> = ({ question, answer }) => {
    const [isOpened, setIsOpened] = useState(false);
    const handleToggleButtonClick = () => setIsOpened(!isOpened);

    return (
        <Styled.Wrapper className={cn({ [Styled.FAQ_ITEM_OPENED_CLASS]: isOpened })}>
            <Styled.ItemQuestion onClick={handleToggleButtonClick}>
                <h3>{question}</h3>
                <Styled.ItemToggleButton />
            </Styled.ItemQuestion>
            <Styled.ItemAnswer>
                <div dangerouslySetInnerHTML={{ __html: answer as string }} />
            </Styled.ItemAnswer>
        </Styled.Wrapper>
    );
};
