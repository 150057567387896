import React, { FC } from 'react';

import { TQuestionItem } from '../../types';
import { QuestionItem } from '../question-item';

import * as Styled from './styled';

type TProps = {
    questions: TQuestionItem[];
};

export const QuestionsList: FC<TProps> = ({ questions }) => {
    const questionsItems = questions?.map((question, index) => <QuestionItem {...question} key={index} />);

    return (
        <Styled.Wrapper>
            <ul>{questionsItems}</ul>
        </Styled.Wrapper>
    );
};
