import { styled } from 'styled-components';

import { mq } from '@/shared/ui/styled';

export const StyledBenefits = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 24px;

    ${mq.gt.sm} {
        background-color: ${({ theme }) => theme.color.secondary};
        border-radius: 12px;
        margin-top: 42px;
        padding: 32px 64px;
        text-align: center;
    }
`;

export const StyledBenefitsList = styled.ul`
    margin-top: 8px;

    ${mq.gt.sm} {
        display: flex;
        justify-content: space-between;
        margin-top: 32px;
    }

    ${mq.lt.md} {
        margin-top: 40px;
    }
`;

export const StyledBenefitsListItem = styled.li`
    background-color: ${({ theme }) => theme.color.secondary};
    border-radius: 12px;
    padding: 42px 16px 16px;
    position: relative;
    text-align: left;

    &.green {
        background-color: #dbfff0;
    }

    &.red {
        background-color: #ffeef0;
    }

    &.yellow {
        background-color: #fff6d8;
    }

    & > i {
        max-width: 44px;
        position: absolute;
        top: -10px;
        ${mq.lt.md} {
            top: -25px;
        }
    }

    & > h3 {
        font-weight: 700;
        margin-bottom: 12px;
    }

    & > p {
        font-size: ${({ theme }) => theme.fontSize.sm};
        font-weight: 500;
        line-height: 1.2;
    }

    & + & {
        margin-top: 24px;
        ${mq.lt.md} {
            margin-top: 40px;
        }
    }

    ${mq.gt.sm} {
        flex: 1;
        padding: 42px 16px 12px 32px;

        & > i {
            max-width: initial;
            left: -10px;
            top: -25px;
        }

        & > p {
            font-weight: 700;
        }

        & + & {
            margin-left: 24px;
            margin-top: 0;
        }
    }
`;
