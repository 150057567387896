import Image from 'next/image';

import { useTranslate } from '@/shared/hooks/use-translate';
import { theme } from '@/shared/theme';
import { Heading } from '@/shared/ui/heading';

import { StyledBenefits, StyledBenefitsList, StyledBenefitsListItem } from './styled';

export const Benefits = () => {
    const { t } = useTranslate();

    return (
        <StyledBenefits>
            <Heading as="h2" size={theme.fontSize.xl} sizeMobile="lg" marginB="24" marginBMobile="16">
                {t('Почему Arenta')}
            </Heading>
            <StyledBenefitsList>
                <StyledBenefitsListItem className="yellow">
                    <i>
                        <Image src="/static/icons/hand.png" width={60} height={60} alt="hand" />
                    </i>
                    <h3>{t('Не нужно покупать товар')}</h3>
                    <p>
                        {t(
                            'Экономьте ваше время и деньги Арендуйте, когда нужно Сдайте обратно, если товар больше не нужен',
                        )}
                    </p>
                </StyledBenefitsListItem>
                <StyledBenefitsListItem className="green">
                    <i>
                        <Image src="/static/icons/shield.png" alt="shield" width={57} height={56} />
                    </i>
                    <h3>{t('Официальный продавец')}</h3>
                    <p>{t('Сервис предоставляет только собственные товары на основании договора')}</p>
                </StyledBenefitsListItem>
                <StyledBenefitsListItem className="red">
                    <i>
                        <Image src="/static/icons/new.png" alt="new-icon" width={57} height={56} />
                    </i>
                    <h3>{t('Качество товара')}</h3>
                    <p>{t('Мы выдаем товар только в отличном состоянии и без дефектов')}</p>
                </StyledBenefitsListItem>
            </StyledBenefitsList>
        </StyledBenefits>
    );
};
