import { TRootStateType } from '@/store';
import { loadPopularProducts } from '@/store/reducers/rent/action-creators';
import { TUserModelState } from '@/store/reducers/user/types';
import { TGetDefaultSettingsSiteParams, loadDefaultData } from '@/store/utils/load-default-data';

import { TRentProduct } from '@/entities/product';

export const loadWithPopularProducts = async (
    params: TGetDefaultSettingsSiteParams,
): Promise<{ user: TUserModelState; popularProducts: TRentProduct[] }> => {
    const { dispatch, getState } = params;
    const { user } = await loadDefaultData(params);
    await dispatch(loadPopularProducts({ cityKatoid: user.cityUser.katoid }));
    const state: TRootStateType = getState();

    return {
        popularProducts: state.rent.popularProducts.data,
        user,
    };
};
