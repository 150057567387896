import { Grid } from 'antd';
import React, { FC, useEffect, useState } from 'react';

import { TArticle } from '../../types';
import { ArticleCard } from '../article-card';
import { ArticlesSlider } from '../articles-slider';

import { StyledPopularProducts } from './styled';

const { useBreakpoint } = Grid;

type TProps = {
    articles: TArticle[];
};

export const PopularArticles: FC<TProps> = ({ articles }) => {
    const breakPoint = useBreakpoint();
    const [isSliderDisabled, setIsSliderDisabled] = useState(false);

    useEffect(() => {
        setIsSliderDisabled(!!breakPoint.xs);
    }, [breakPoint]);

    return (
        <StyledPopularProducts>
            {!isSliderDisabled && <ArticlesSlider articles={articles} />}
            {isSliderDisabled && (
                <>
                    {articles.map((product, index) => (
                        <ArticleCard {...product} key={index} />
                    ))}
                </>
            )}
        </StyledPopularProducts>
    );
};
