import Image from 'next/image';
import Link from 'next/link';
import React, { FC } from 'react';

import { ARTICLES_URL } from '@/shared/constants/paths';
import { IMAGE_ALT_CUT_LENGTH } from '@/shared/constants/settings';
import { useTranslate } from '@/shared/hooks/use-translate';
import { cutString } from '@/shared/utils/string';

import { TArticle } from '../../types';

import { StyledArticleCard, StyledArticleCardContent, StyledArticleCardTitle, StyledArticleCardButton } from './styled';

export const ArticleCard: FC<TArticle> = ({ imageUrl, title, slug }) => {
    const { t } = useTranslate();

    return (
        <StyledArticleCard>
            {imageUrl && (
                <div className="image-block">
                    <Image src={imageUrl} alt={cutString(title, IMAGE_ALT_CUT_LENGTH)} fill />
                </div>
            )}
            <StyledArticleCardContent>
                {/* TODO в дальнейшем категории могут пригодиться */}
                {/*<StyledArticleCardCategory>{categoryName}</StyledArticleCardCategory>*/}
                <StyledArticleCardTitle>{title}</StyledArticleCardTitle>
            </StyledArticleCardContent>
            <Link href={`${ARTICLES_URL}/${slug}`}>
                <StyledArticleCardButton>{t('Читать')} 👀</StyledArticleCardButton>
            </Link>
        </StyledArticleCard>
    );
};
