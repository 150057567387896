import Head from 'next/head';
import { FC } from 'react';

type TProps = {
    title: string | undefined;
    description?: string;
    keywords?: string;
};

export const HeadMeta: FC<TProps> = ({ title, description, keywords }) => {
    return (
        <Head>
            <title>{title}</title>
            {description && <meta content={description} name="description" />}
            {keywords && <meta name="keywords" content={keywords} />}
        </Head>
    );
};
