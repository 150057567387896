import Image from 'next/image';
import { FC } from 'react';

import { useTranslate } from '@/shared/hooks/use-translate';

import { StyledHowToUse, StyledHowToUseStep } from './styled';

export const HowToUse: FC = () => {
    const { t } = useTranslate();

    return (
        <StyledHowToUse>
            <StyledHowToUseStep>
                <span style={{ position: 'relative', width: 37, height: 37, display: 'block' }}>
                    <Image
                        fill
                        sizes="37 37 37"
                        src="/static/icons/man_by_computer_icon.png"
                        alt="man by computer icon"
                    />
                </span>
                <p>{t('Выбери товар')}</p>
            </StyledHowToUseStep>
            <StyledHowToUseStep>
                <span style={{ position: 'relative', width: 37, height: 37, display: 'block' }}>
                    <Image fill src="/static/icons/box_icon.png" alt="box icon" />
                </span>
                <p>{t('Получи его в точке выдачи')}</p>
            </StyledHowToUseStep>
            <StyledHowToUseStep>
                <span style={{ position: 'relative', width: 37, height: 37, display: 'block' }}>
                    <Image fill src="/static/icons/firework_icon.png" alt="firework icon" />
                </span>
                <p>{t('Пользуйся')}</p>
            </StyledHowToUseStep>
        </StyledHowToUse>
    );
};
