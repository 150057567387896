import { styled } from 'styled-components';

import { mq, colXMiddle } from '@/shared/ui/styled';

export const StyledHowToUse = styled.div`
    display: flex;
    background-image: url('/static/images/how-to-use-bg.png');
    background-color: ${({ theme }) => theme.color.secondary};
    border-radius: 12px;
    margin-top: 24px;
    padding: 29px 12px 42px;

    ${mq.lt.md} {
        flex-direction: column;
        justify-content: space-around;
        padding: 42px 12px;
    }

    ${mq.lt.sm} {
        padding: 20px 12px;
    }
`;

export const StyledHowToUseStep = styled.div`
    flex: 1 2;
    font-size: ${({ theme }) => theme.fontSize.lg};
    font-weight: 700;
    text-align: center;
    ${colXMiddle};

    & > span {
        margin-bottom: 16px !important;
    }

    ${mq.lt.md} {
        background-color: ${({ theme }) => theme.color.secondary};
        border-radius: 12px;
        box-shadow: 0 4px 8px rgb(0 0 0 / 8%);
        width: fit-content;
        max-width: 180px;
        padding: 32px 16px 16px;
        position: relative;
        text-align: left;
        margin-bottom: 0;
        ${colXMiddle};

        & > span {
            position: absolute !important;
            top: -15px;
            left: 22px;
        }

        & + & {
            margin-top: 0;
        }

        &:last-child {
            margin-bottom: 0;
            margin-top: 16px;
        }

        &:nth-of-type(even) {
            align-self: end;
        }
    }

    ${mq.lt.sm} {
        padding: 20px 16px;
        font-size: ${({ theme }) => theme.fontSize.md};
        max-width: 145px;

        & > span {
            position: absolute !important;
            top: -25px;
            left: 12px;
        }

        & + & {
            margin-top: 40px;
        }
    }
`;
