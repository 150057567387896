import { ResourceService, TReadParamsProperties } from '@/shared/api/base-services/resource-service';

import { TQuestionResponse } from './types';

export const QuestionsService = ResourceService('faqs');

export const getQuestions = async (locale: string, isPopular?: boolean): Promise<TQuestionResponse> => {
    const popular = isPopular !== undefined ? { isPopular } : {};

    const responseQuestions = await QuestionsService.read<TQuestionResponse, TReadParamsProperties>({
        params: {
            locale,
            ...popular,
        },
    });

    return responseQuestions;
};
