import { styled } from 'styled-components';

import { Button } from '@/shared/ui/button';
import { mq } from '@/shared/ui/styled';

export const StyledArticleCard = styled.div`
    display: grid;
    background-color: ${({ theme }) => theme.color.secondary};
    border-radius: 12px;
    overflow: hidden;
    padding: 12px;
    grid-template-columns: auto 2fr;

    .image-block {
        position: relative;
        width: 96px;
        height: 75px;
        margin-right: 16px;

        img {
            object-fit: cover;
            border-radius: 12px;
        }
    }

    ${mq.gt.sm} {
        padding: 0;
        grid-template-columns: 1fr;

        .image-block {
            width: 100%;
            height: 204px;
            position: relative;
            margin-bottom: 16px;

            img {
                border-bottom-left-radius: 0;
                border-bottom-right-radius: 0;
            }
        }
    }
`;

export const StyledArticleCardContent = styled.div`
    ${mq.gt.sm} {
        margin: 0 12px;
    }
`;

export const StyledArticleCardCategory = styled.div`
    display: inline-block;
    color: ${({ theme }) => theme.color.neutral500};
    font-size: ${({ theme }) => theme.fontSize.xs};
    margin-bottom: 12px;
`;

export const StyledArticleCardTitle = styled.h3`
    font-size: ${({ theme }) => theme.fontSize.xs};
    font-weight: 500;
    -webkit-line-clamp: 3;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    height: 50px;

    ${mq.gt.sm} {
        font-size: ${({ theme }) => theme.fontSize.md};
    }
`;

export const StyledArticleCardButton = styled(Button).attrs({
    fontSize: 'xs',
})`
    color: ${({ theme }) => theme.color.dark};
    font-weight: 700;
    margin-top: 16px;
    justify-self: left;

    & > span {
        margin-left: 8px !important;
    }

    ${mq.gt.sm} {
        margin-left: 12px;
        margin-bottom: 12px;
    }
`;
