import { TReadParamsProperties } from '@/shared/api';
import { ResourceService } from '@/shared/api/base-services/resource-service';

import { TMetaResponse, TMetaDataReturned } from './types';

export const MetaDataService = ResourceService('metadata');

export const getMetaDataPage = async (pageUrl: string, locale: string): Promise<TMetaDataReturned> => {
    const responseMetaData = await MetaDataService.read<TMetaResponse, TReadParamsProperties>({
        params: {
            page_url: pageUrl,
            locale,
        },
    });

    const title = responseMetaData.data[0]?.header;

    const description = responseMetaData.data[0]?.description;

    return { title, description };
};
