import { styled } from 'styled-components';

import { mq } from '@/shared/ui/styled';

export const StyledSliderItem = styled.div<{ background?: string }>`
    width: 100%;
    height: 375px;
    background: ${({ background }) => background && `url(${background})`};
    background-size: cover;
    background-repeat: no-repeat;
    padding: 24px;
    border-radius: 12px;
    display: flex;
    align-items: flex-end;

    &.hidden-by-md {
        display: none;
    }

    ${mq.lt.md} {
        height: 180px;
        padding: 10px 8px;
        display: none;

        &.hidden-by-md {
            display: flex;
        }
    }
`;

export const StyledSliderItemInfo = styled.div`
    color: ${({ theme }) => theme.color.secondary};
    position: relative;
    z-index: 2;
`;

export const StyledSliderItemInfoTitle = styled.h3`
    color: ${({ theme }) => theme.color.secondary};
    font-size: ${({ theme }) => theme.fontSize.xxxxl};
    font-weight: 700;

    ${mq.lt.md} {
        font-size: ${({ theme }) => theme.fontSize.lg};
    }
`;

export const StyledSliderItemInfoSubtitle = styled.div`
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    font-size: ${({ theme }) => theme.fontSize.xs};
    color: ${({ theme }) => theme.color.secondary};
    font-weight: 400;
    margin-top: 5px;

    p:first-child {
        text-shadow: 0 2px 1px rgb(0 0 0 / 16%);

        span {
            display: none;
        }
    }

    p:nth-child(2) {
        display: flex;
        justify-content: center;
        color: #7e1ddf;
        font-weight: 800;
        background: ${({ theme }) => theme.color.secondary};
        border-radius: 54px;
        padding: 8px;
        margin: 5px 0 0;

        span {
            display: inline-block;
            margin-right: 5px;
        }
    }

    ${mq.gt.sm} {
        font-size: ${({ theme }) => theme.fontSize.xl};
        flex-direction: row;
        background: ${({ theme }) => theme.color.secondary};
        padding: 13px 16px;
        border-radius: 130px;
        color: ${({ theme }) => theme.color.dark};
        font-weight: 500;
        margin-top: 20px;
        align-items: center;
        justify-content: center;

        p:first-child {
            span {
                display: inline-block;
            }
        }

        p:nth-child(2) {
            max-width: 100%;
            padding: 0;
            margin: 0 0 0 10px;

            span {
                display: none;
            }
        }
    }
`;
