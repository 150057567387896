import React, { FC } from 'react';
import { Navigation } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/css';
import { TArticle, ArticleCard } from '@/entities/article';

import { ArrowIcon } from '@/shared/ui/icons';

import {
    StyledProductsSlider,
    StyledProductSliderContainer,
    StyledProductSliderPrevNavButton,
    StyledProductSliderNextNavButton,
} from './styled';

type TProps = {
    articles: TArticle[];
};

export const ArticlesSlider: FC<TProps> = ({ articles }) => {
    return (
        <StyledProductsSlider>
            <StyledProductSliderContainer>
                <Swiper
                    modules={[Navigation]}
                    slidesPerView="auto"
                    navigation={{
                        nextEl: '.product-swiper-button-next',
                        prevEl: '.product-swiper-button-prev',
                    }}
                >
                    {articles.map((articleProps, index) => (
                        <SwiperSlide key={index}>
                            <ArticleCard {...articleProps} />
                        </SwiperSlide>
                    ))}
                </Swiper>
                <StyledProductSliderPrevNavButton className="product-swiper-button-prev">
                    <ArrowIcon />
                </StyledProductSliderPrevNavButton>
                <StyledProductSliderNextNavButton className="product-swiper-button-next">
                    <ArrowIcon />
                </StyledProductSliderNextNavButton>
            </StyledProductSliderContainer>
        </StyledProductsSlider>
    );
};
